import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { editLeague } from '../../redux/slices/league';
import { useLocation } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const options: any = { id: [], activated: ['true', 'false'], color: ['#ddd', "#000"], imageUrl: [''], name: ['European League', 'Arabic League'], shortName: ["EPL", "EFL"], createdDate: [''], isPaid: ['true', 'false'] }

export const League = () => {

    const location = useLocation();
    const dispatch = useDispatch<AppDispatch>()

    const [open, setOpen] = React.useState(false);
    const [newLeague, setNewLeague] = useState<any>(location.state)

    useEffect(() => {
        setNewLeague(location.state)
    }, [location.state])

    const handleChangeValue = (event: any, item: string) => {

        let newObj: any = { ...location.state };
        newObj[item] = event.target.value
        setNewLeague(newObj)
    }

    const handleSave = () => {
        dispatch(editLeague(newLeague))
        setOpen(true);

    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    return (
        <div><div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 15 }}>
            {location.state && Object.entries(location.state).map((item, index) => {
                return (
                    <div style={{ marginBottom: 15, marginRight: 15 }}>
                        <Autocomplete
                            disablePortal
                            autoComplete={true}
                            disabled={(item[0] === 'id' || item[0] === 'createdDate' || item[0] === 'matchesCount') ? true : false}
                            id="combo-box-demo"
                            options={options[item[0]] ?? ['something']}
                            onChange={(event, value) => { handleChangeValue(value, item[0]) }}
                            sx={{ width: 300 }}
                            // defaultValue={newLeague[item[0]]?.toString()}
                            value={newLeague[item[0]]}
                            onSelect={(event) => { handleChangeValue(event, item[0]) }}
                            onInput={(event) => { handleChangeValue(event, item[0]) }}
                            renderInput={(params) => <TextField {...params} label={item[0]} />}
                        />
                    </div>
                )
            })}
        </div>
            <Button onClick={handleSave} style={{ padding: "10px 30px" }} variant='contained' >Save</Button>
            <div >
                <Snackbar sx={{ height: "5%" }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={open} autoHideDuration={5000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
                        League is successfully saved.
                    </Alert>
                </Snackbar>
            </div>
        </div>
    )
}
