import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Routes, //replaces "Switch" used till v5
  Route,
} from "react-router-dom";
import { Home } from './pages/Home';
import { Dashboard } from './pages/Dashboard';
import { SideBar } from './components/sidebar';
import { Leagues } from './pages/leagues/Leagues';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { League } from './pages/leagues/League';

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <Router>
          <div className="container">
            <div className="sidebar">
              <SideBar />
            </div>
            <div className='contentContainer'>
              <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/leagues" element={<Leagues />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/leagues/:id" element={<League />} />
                <Route path={'*'} element={<Home />} />
              </Routes>
            </div>
          </div>
        </Router>
      </div>
    </Provider>
  );
}

export default App;
