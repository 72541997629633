import { ActionReducerMapBuilder, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { editLeagueApi, getLeaguesApi } from '../../api/leagues';
import { ILeague, MatchTimeStatus } from '../../types';

export interface IFetchLeague {
    leagueType?: any;
    take?: number;
    cursor?: string;
    search?: string | undefined;
    matchStatus: keyof typeof MatchTimeStatus;
    activated?: string;
}

interface IState {
    leagues: [] | ILeague[];
}
const initialState: IState = {
    leagues: [],
}


export const fetchLeagues = createAsyncThunk('leagues', async (props: IFetchLeague) => {
    const response = await getLeaguesApi(props)
        .then(({ data }) => data)
        .catch(err => {
            console.log('[GET_Leagues] error', err);
        });
    return { response, cursor: props.cursor };
});

export const editLeague = createAsyncThunk('leagues/edit', async (param: ILeague) => {
    const response = await editLeagueApi(param)
        .then(({ data }) => data)
        .catch(err => {
            console.log('[edit_Leagues] error', err);
        });
    return response;
});

const leagueSice = createSlice({
    name: 'leagues',
    initialState,
    reducers: {},

    extraReducers: (builder: ActionReducerMapBuilder<any>) => {
        return builder.addCase(fetchLeagues.pending, state => {
            state.loading = true;

        }),
            builder.addCase(fetchLeagues.fulfilled, (state, action: PayloadAction<any>) => {
                if (action.payload.cursor) {
                    console.log('cursor true')
                    state.leagues = [...state.leagues, ...action.payload.response];
                } else {
                    state.leagues = action.payload.response;
                }
                state.loading = false;
            }),
            builder.addCase(fetchLeagues.rejected, state => {
                state.loading = false;
                console.log('[Fetch Leagues rejected] ');
                state.leagues = [];
                state.loading = false;

            }),
            builder.addCase(editLeague.pending, state => {
                state.loading = false;
            }),
            builder.addCase(editLeague.fulfilled, (state, action: PayloadAction<ILeague>) => {
                let newLeagues = state.leagues.map((league: ILeague) => league.id === action.payload.id ? action.payload : league)
                state.leagues = newLeagues;
            }),
            builder.addCase(editLeague.rejected, state => {
                console.log('rejected Edit league: ' + state.rejected)
            });

    },
})

// export const { } = leagueSice.actions

export default leagueSice.reducer