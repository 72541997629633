import { ILeague } from "../types";
import { apiAxios } from "./api";

export const getLeaguesApi = async ({ leagueType, search, matchStatus, activated, cursor }: any) => {

    let url = `/leagues?take=100&activated=${activated}${leagueType ? '&leagueType=' + leagueType.toUpperCase() : ''}${search ? "&search=" + search : ""}${matchStatus ? "&matchTimeStatusCount=" + matchStatus : ""}${cursor ? "&cursor=" + cursor : ""}`


    console.log('leagues url', url)

    return apiAxios(url)
};

export const editLeagueApi = async (newObj: ILeague) => {
    return apiAxios({ method: 'PUT', url: '/leagues', data: newObj })
};

export const getMoreLeagauesApi = async (id: string) => {
    return apiAxios(`/leagues?cursor=${id}&activated=true`);
};
