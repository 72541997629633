import React from 'react'
import { alpha } from '@mui/material/styles';
import { EnhancedTableToolbarProps } from './types';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FilterListIcon from '@mui/icons-material/FilterList';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

export function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;

    const handleDeleteItem = () => {
        console.log("delete icon");

    }
    const handleEditItem = () => {

    }
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Leagues
                </Typography>
            )}
            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <>
                        <Link to={`${numSelected}`} state={ props.league}>
                            <IconButton onClick={handleEditItem}>
                                <EditIcon />
                            </IconButton>
                        </Link>
                        <IconButton onClick={handleDeleteItem}>
                            <DeleteIcon />
                        </IconButton>
                    </>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}