export const enum MatchTimeStatus {
    NOT_STARTED = 'NOT_STARTED',
    IN_PLAY = 'IN_PLAY',
    TO_BE_FIXED = 'TO_BE_FIXED',
    ENDED = 'ENDED',
    POSTPONED = 'POSTPONED',
    CANCELLED = 'CANCELLED',
    WALKOVER = 'WALKOVER',
    INTERRUPTED = 'INTERRUPTED',
    ABANDONED = 'ABANDONED',
    RETIRED = 'RETIRED',
    SUSPENDED = 'SUSPENDED',
    DECIDED_BY_FA = 'DECIDED_BY_FA',
    REMOVED = 'REMOVED',
}

export const matchTimeStatusOption = ['NOT_STARTED', 'IN_PLAY', 'TO_BE_FIXED', 'ENDED', 'POSTPONED', 'CANCELLED', 'WALKOVER', 'INTERRUPTED', 'ABANDONED', 'RETIRED', 'SUSPENDED', 'DECIDED_BY_FA', 'REMOVED',]
export interface ILeague {
    id: string,
    name: string,
    shortName: string,
    activated: boolean | string | number,
    color?: string,
    imageUrl: string,
    type: string,
    countryCode: string,
    createdDate: string,
    matchesCount: number,
    isPaid: boolean | string | number,
}