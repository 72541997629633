import React, { useEffect }         from 'react'
import styles                       from './leagues.module.css'
import ToggleButton                 from '@mui/material/ToggleButton';
import ToggleButtonGroup            from '@mui/material/ToggleButtonGroup';
import { styled, alpha }            from '@mui/material/styles';
import InputBase                    from '@mui/material/InputBase';
import SearchIcon                   from '@mui/icons-material/Search';
import EnhancedTable                from '../../components/Table';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLeagues }             from '../../redux/slices/league';
import { MatchTimeStatus }          from '../../types';
import { AppDispatch, RootState }   from '../../redux/store';
import BasicSelect                  from '../../components/select';


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    bottom: '0px',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));


const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        border: '1px solid #ddd',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));



export const Leagues = () => {
    const [alignment, setAlignment] = React.useState('web');
    const [activated, setActivated] = React.useState(false);

    const dispatch = useDispatch<AppDispatch>();
    const { leagues } = useSelector((state: RootState) => state.leagues);
    const handleSearchLeague = (event: any) => {

        dispatch(fetchLeagues({ search: event.target.value, matchStatus: MatchTimeStatus.NOT_STARTED, activated: `${activated}` }));
    }

    const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
        setAlignment(newAlignment);
    };

    const handleSwitchLeagues = (activate: boolean) => {
        setActivated(activate)
        dispatch(fetchLeagues({ activated: `${activate}`, matchStatus: MatchTimeStatus.NOT_STARTED }))
    }

    useEffect(() => {

        if (!leagues.length) {
            dispatch(fetchLeagues({ matchStatus: MatchTimeStatus.NOT_STARTED, activated: "true", }))
        }
    }, [])

    return (
        <div className="mainContainer">
            <div className={styles.header}>
                Enabled:list of leagues that enabled to users in app
            </div>
            <div className={styles.container}>
                {/* <BasicSelect /> */}
                <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                >
                    <ToggleButton onClick={() => handleSwitchLeagues(true)} value="Enabled">activated</ToggleButton>
                    <ToggleButton onClick={() => handleSwitchLeagues(false)} value="Disabled">Not activated</ToggleButton>
                </ToggleButtonGroup>

                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search league..."
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={handleSearchLeague}

                    />
                </Search>
            </div>
            <div>
                <EnhancedTable />
            </div>

        </div>
    )
}
