import React from 'react'

export const Home = () => {
  return (
    <div>
        <span>
            home
        </span>
    </div>
  )
}
