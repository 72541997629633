import * as React                    from "react";
import Box                           from "@mui/material/Box";
import Table                         from "@mui/material/Table";
import TableBody                     from "@mui/material/TableBody";
import TableCell                     from "@mui/material/TableCell";
import TableContainer                from "@mui/material/TableContainer";
import TablePagination               from "@mui/material/TablePagination";
import TableRow                      from "@mui/material/TableRow";

import Paper                         from "@mui/material/Paper";
import Checkbox                      from "@mui/material/Checkbox";

import { getComparator, stableSort } from "./data";
import { Order }                     from "./types";
import { AppDispatch, RootState }    from "../../redux/store";
import { useDispatch, useSelector }  from "react-redux";
import { ILeague, MatchTimeStatus }  from "../../types";
import { EnhancedTableToolbar }      from "./TableToolBar";
import { TableMatch }                from "./TableMatch";
import { useEffect }                 from "react";
import { fetchLeagues }              from "../../redux/slices/league";

export default function EnhancedTable() {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof ILeague>("id");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [row, setRow] = React.useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const { leagues } = useSelector((state: RootState) => state.leagues);

  console.log("leagues", leagues);
  const dispatch = useDispatch<AppDispatch>();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ILeague
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = leagues.map((n: any) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const handleClick = (
    event: React.MouseEvent<unknown>,
    name: string,
    row: any
  ) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setRow(row);
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty leagues.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - leagues?.length) : 0;

  let array: any = Array.from(
    new Set(leagues.map((item: any) => Object.keys(item)).flat())
  );

  useEffect(() => {
    if (page === leagues.length / rowsPerPage - 1) {
      dispatch(
        fetchLeagues({
          matchStatus: MatchTimeStatus.NOT_STARTED,
          activated: "true,false",
          cursor: leagues[leagues.length - 1].id,
        })
      );
    }
  }, [page]);

  return (
    <Box sx={{ width: "99%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar league={row} numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 500 }}
            aria-labelledby="tableTitle"
            size={"small"}
          >
            <TableMatch
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={leagues?.length}
              leagues={leagues}
            />
            <TableBody>
              {stableSort(leagues as any, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name as any);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event) =>
                        handleClick(event, row.name as any, row)
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.id && row.id.toString().slice(0, 6)}...
                      </TableCell>
                      {array.slice(1).map((item: keyof typeof row) => (
                        <TableCell  align="left">
                          {row[item]?.toString().length > 19
                            ? row[item].toString().slice(0, 18) + "..."
                            : row[item]?.toString()}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          count={leagues?.length}
          component="div"
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
