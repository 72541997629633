import axios from 'axios';

export const apiAxios = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    'content-type': 'application/json',
    // 'Access-Control-Allow-Origin':'*'
  },
});

apiAxios.interceptors.request.use(async config => {
//   let token = await getToken();
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
  return config;
});


